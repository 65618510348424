@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.Home {
    position: relative;
    width: 100%;
    top: 0px;
    display: flex;
    align-items: flex-start;
    margin-top: 3%; 
    font-family: "Inter", sans-serif;
    flex-wrap: wrap; 
    padding-top: 1rem; 
}

.image-container {
    flex: 0 0 auto; 
    margin-left: 5%; 
}

.image-container img {
    border-radius: 1rem; 
    width: 34rem;
}

.about-me-container {
    flex: 1;
    margin-left: 5%; 
    margin-right: 8%; 
    align-items: flex-start;
}

.about-me-container h2 {
    font-size: 2.5rem; 
    font-weight: 800;
}

.about-me-container p {
    font-size: 1.9rem; 
    font-weight: 500;
}

.social-container {
    display: flex; 
    flex-direction: column; 
    margin-top: 8%; 
    text-decoration: none;
    align-items: center;
}

.social-links {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
}

.social-links a {
    text-decoration: none;
}

.circle {
    width: 10vw; 
    height: 6vh; 
    border-radius: 1rem; 
    border: 2px solid #92a8d1;
    background-color: #801AE5;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5rem; 
    font-family: 'Inter', sans-serif;
    font-size: 1.7rem; 
    margin-bottom: 1%; 
}

.circle:hover {
    background-color: #66CDAA; 
}

@media (max-width: 1663px) {
    .circle{
        width: 10vw;
        height: 4vw; 
        margin-right: 4rem;
    }
    .about-me-container p {
        font-size: 1.6rem; 
    }
}

@media (max-width: 1540px) {
    .circle{
        width: 10vw;
        height: 4vw; 
        margin-right: 2rem;
    }
}

@media (max-width: 1356px) {
    .about-me-container p {
        font-size: 1.3rem; 
    }
    .circle{
        width: 10vw;
        height: 4.5vw; 
        margin-right: 1rem;
        font-size: 1.3rem;
    }
    .image-container img {
        width: 29rem;
    }


}

@media (max-width: 1000px) {
    .Home {
        flex-direction: column; 
        align-items: center;
        justify-content:center;
    }

    .about-me-container {
        margin-left: 8%; 
        margin-right: 8%;
        margin-top: 5%; 
    }

    .about-me-container h2 {
        font-size: 2rem;
    }

    .about-me-container p {
        font-size: 1.4rem; 
    }

    .circle {
        width: 15vw;
        height: 6vw; 
        margin-right: 1rem; 
        font-size: 1.2rem;
        margin-right: 3rem;
    }
    .image-container img {
        width: 30rem;
    }
}


@media (max-width: 768px) {
    .circle {
        width: 15vw; 
        height: 6vw;
    }
}

@media (max-width: 570px) {
    .Home {
        margin-right: 3%; 
    }
    .image-container img {
        width: 25rem;
    }
    .circle {
        width: 19vw; 
        height: 8vw; 
        margin-right: 1rem
    }
}
@media (max-width: 480px) {
    .Home {
        padding: 3%; 
    }

    .about-me-container h2 {
        font-size: 1.5rem; 
    }

    .about-me-container p {
        font-size: 1rem; 
    }

    .circle {
        width: 19vw; 
        height: 9vw; 
        font-size: 0.8rem;
        margin-right: 0.5rem;
    }
    .image-container img {
        width: 18rem;
    }
}

