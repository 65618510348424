* {
    box-sizing: border-box;
    margin: 0; 
    padding: 0;
    background: transparent;
    margin-bottom: 5px;
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
}

body {
    margin: 0;
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    color: #fff;
    background: linear-gradient(#100819, #29153D, #471737, #100819); 
    min-height: 100vh; 
    padding: 2rem;
}

.App {
    height: 100%;
    width: 100%;
}



