@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.Main-Container {
    position: relative;
    width: 100%;
    top: 50px;
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    margin-left: 5%;
    font-family: "Inter", sans-serif;
    padding-top: 2rem;
}
.content-container {
    display: flex;
    flex-direction: row; 
    align-items: flex-start; 
    width: 100%; 

}
.list {
    width: 57%;
    margin-top: 20px;
}

.line {
    border-left: 4px solid #801AE5;
    height: 150px;
}
.jobs {
    list-style-type: disc; 
    padding: 0; 
}

.job {
    margin-bottom: 40px; 
}
.jobs li::marker {
    color: #801AE5; 
}
.job-description-container {
    display: flex; 
    align-items: flex-start; 
}

.line {
    width: 5px; 
    background-color: #302938;
    margin-right: 2%;
}

.paragraph-container {
    margin: 0; 
    width: 70%
}


.paragraph-container p {
    font-size: 16px;
}




@media (max-width: 1260px) {
    .fun-fact-circle {
        width: 350px; 
        height: 350px; 
        border-radius: 50%; 
    }
    .line {
        height: 10vw;
        margin-right: 4%;
    }
}

@media (max-width: 1040px) {
    .fun-fact-circle {
        width: 350px; 
        height: 350px; 
        border-radius: 50%; 
    }
    .content-container {
        display: flex; 
        flex-direction: column; 
        align-items: flex-start; 
        width: 100%;
    }
    .paragraph-container {
        margin: 0; 
        width: 1000%
    }
    .job {
        width: 70vw
    }
    .line {
        height: 120px;
        margin-right: 4%;
    }


}

@media (max-width: 768px) {
    .line {
        height: 150px;
        margin-right: 4%;
    }
    .Main-Container {
        margin-bottom: 20%;

    }
}

@media (max-width: 558px) {
    .line {
        height: 220px;
        margin-right: 4%;
    }

}