@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.main-skills {
    position: relative;
    width: 100%;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 4%;  
    font-family: "Inter", sans-serif;
}

.skills-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.skills-container {
    width: 100%;
    margin-top: 30px;
}

.s-container {
    width: 100%;
    overflow: hidden; 
    margin-bottom: 20px; 
    position: relative; 
}

.skillss {
    display: flex;
    gap: 10px;
    white-space: nowrap; 
    width: 260%; 
    animation: swipe 30s linear infinite;
}

.s-container.reverse .skillss {
    animation: swipe-reverse 30s linear infinite;
}

@keyframes swipe {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes swipe-reverse {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

.skill {
    background-color: #801AE5;
    font-size: 16px; 
    padding: 0 10px;
    border-radius: 10px;
    width: 150px; 
    height: 50px; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box; 
}

@media (max-width: 950px) {
    .skill {
        width: 120px; 
        height: 40px; 
        font-size: 14px; 
    }
}

@media (max-width: 758px) {
    .skill {
        width: 100px; 
        height: 30px; 
        font-size: 12px;
    }
}
