.main-container {
    position: relative;
    width: 100%;
    top: 40px;
    display: flex;
    flex-direction: column; 
    align-items: flex-start;
    margin-left: 5%;
    font-family: "Inter", sans-serif;
    padding-top: 2rem;
}

.project-container {
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    margin-top: 20px; 
    padding-right: 1%;
}

.project-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.project-link {
    text-decoration: none;
}

.project {
    width: 30rem;
    height: 15rem;
    border-radius: 10px;
    background-color: #140C1C;
    color: white;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    margin-right: 3rem;
    margin-bottom: 3rem; 
    font-family: 'Inter', sans-serif;
    border: 3px solid #302938;
    position: relative; 
    overflow: hidden; 
    transition: background-color 0.3s ease;
    padding: 4rem;
    text-align: center;
    border: 2px solid #92a8d1;
}

.project:hover {
    background-color: #801AE5;
}

.project-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    font-size: 30px;
    font-weight: bold;
    transition: top 0.3s ease, transform 0.3s ease; 
}

.project:hover .project-name {
    top: 10px;
    transform: translateX(-50%); 
}

.project-description {
    position: absolute;
    top: 100%; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 1rem;
    font-size: 15px;
    text-align: center;
    transition: top 0.3s ease; 
}

.project:hover .project-description {
    top: 0; 
}

.funfact-container {
    padding: 3rem; 
}

@media (max-width: 1680px) {
    .project {
    width: 27rem;
    height: 12rem;
    }
    .project-description {
        font-size: 13px;
    }
}

@media (max-width: 1550px) {
    .project {
    width: 24rem;
    height: 11rem;
    }
    .project-description {
        font-size: 11.6px;
    }
}

@media (max-width: 975px) {
    .project {
    width: 40rem;
    height: 11rem;
    }
}

@media (max-width: 851px) {
    .project {
    width: 35rem;
    height: 10rem;
    margin-bottom: 5rem;

    }
}

@media (max-width: 736px) {
    .project {
        width: 24rem;
        height: 9rem;
        margin-bottom: 1rem;
    }
    .project-description {
        font-size: 9px;
    }
    .funfact-container {
        padding: 1rem; 
    }
}

@media (max-width: 440px) {
    .project {
        width: 20rem;
        height: 8rem;
        margin-bottom: 2rem;
    }
    .project-description {
        font-size: 7px;
    }
    .project-name {
        font-size: 18px;
    }
}